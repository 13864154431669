import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container, Inner } from '../components/Elements';
import SEO from '../components/SEO';
import PostList from '../components/PostList';
import PhoneCTA from '../components/PhoneCTA';

const Wrapper = styled.div`
  padding-top: 8.8rem;
  ${(props) => props.theme.mq.medium`
      padding-top: 7.2rem;
    `};

  ${Inner} {
    flex-wrap: wrap;
  }

  .section-title {
    width: 100%;
    text-align: center;
    margin-top: 12.8rem;
    margin-bottom: 6.4rem;

    ${(props) => props.theme.mq.small`
      margin-top: 9.6rem;
      margin-bottom: 4.8rem;
    `};
  }

  .section-posts {
    width: 100%;
  }
`;

BlogPage.propTypes = { pageContext: PropTypes.object };

export default function BlogPage({
  pageContext: { pageNumber, hasNextPage, posts, featuredPost },
}) {
  const seoTitle =
    pageNumber === 1 ? 'Blog | Braid' : `Blog | Page ${pageNumber} | Braid`;
  const seoDescription =
    pageNumber === 1 ? 'Braid blog posts.' : `Braid blog page ${pageNumber}`;

  return (
    <Wrapper>
      <SEO
        title={seoTitle}
        description={seoDescription}
        slug="/blog"
        overrideCanonical
      />
      <Container>
        <Inner>
          <div className="section-title">
            <h1>Braid Blog</h1>
          </div>
          <div className="section-posts">
            <PostList
              {...{
                pageNumber,
                hasNextPage,
                posts,
                featuredPost: pageNumber === 1 ? featuredPost : null,
              }}
            />
          </div>
        </Inner>
      </Container>
      <PhoneCTA buttonText="Get the App" />
    </Wrapper>
  );
}
