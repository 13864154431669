import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import BackgroundImage from './BackgroundImage';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  a {
    display: flex;
    width: 100%;
    margin-bottom: 6.4rem;
    color: black;
    gap: 6.4rem;

    &:hover {
      color: black;

      h2 {
        color: ${(props) => props.theme.colors.purple.hover};
      }
    }

    ${(props) => props.theme.mq.small`
    margin-bottom: 4rem;
      `}

    .post-details {
      width: 50%;

      h2 {
        transition: all 0.3s ease;
        ${(props) => props.theme.font.j2};
        margin-bottom: 2rem;

        ${(props) => props.theme.mq.large`
            margin-bottom: 1.2rem;
          `}
      }
      p {
        ${(props) => props.theme.font.p1};
        margin-bottom: 4rem;
        ${(props) => props.theme.mq.large`
            margin-bottom: 2rem;
          `}
      }
      small {
        ${(props) => props.theme.font.p1};
        color: ${(props) => props.theme.colors.text.tertiary};
        margin: 0;

        ${(props) => props.theme.mq.medium`
            ${(props) => props.theme.font.p2};
          `}
      }
    }
    .post-image {
      width: 50%;
      border-radius: 0.8rem;
      position: relative;
      overflow: hidden;

      .post-image-inner {
        width: 100%;
        padding-bottom: 83.3333334%;
      }
    }
    ${(props) => props.theme.mq.large`
        gap: 0;
        flex-wrap: wrap;

        .post-image {
          width: 100%;
          order: 0;
          margin-bottom: 2rem;

          .post-image-inner {
            padding-bottom: 50.3906%;
          }
        }
        .post-details {
          width: 100%;
          order: 1;
        }
      `}
  }
`;

FeaturedPost.propTypes = {
  featuredPost: PropTypes.object.isRequired,
};

export default function FeaturedPost({ featuredPost }) {
  return (
    <Wrapper>
      <Link title={featuredPost.data.title} to={`/post/${featuredPost.uid}`}>
        <div className="post-details">
          <div className="post-details-inner">
            <h2>{featuredPost.data.title}</h2>
            <p>{featuredPost.data.meta_description}</p>
          </div>
        </div>
        <div className="post-image">
          <div className="post-image-inner"></div>
          <BackgroundImage
            image={featuredPost.data.og_image.gatsbyImageData}
            alt={featuredPost.data.title}
          />
        </div>
      </Link>
    </Wrapper>
  );
}
