import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import BackgroundImage from './BackgroundImage';
import FeaturedPost from './FeaturedPost';

const PostLink = styled(Link)`
  width: calc(50% - 3.2rem);
  color: black;
  &:hover {
    color: black;

    h2 {
      color: ${(props) => props.theme.colors.purple.hover};
    }
  }

  h2 {
    transition: all 0.3s ease;
    ${(props) => props.theme.font.genericH3};
    font-family: Calibre;
    margin-bottom: 0.4rem;
  }
  p {
    ${(props) => props.theme.font.p1};
    margin-bottom: 0.4rem;

    ${(props) => props.theme.mq.small`
      display: none;
    `}
  }
  small {
    ${(props) => props.theme.font.p1};
    color: ${(props) => props.theme.colors.text.tertiary};
    margin: 0;
    ${(props) => props.theme.mq.medium`
    ${(props) => props.theme.font.p2};
    `}
  }

  .post-image {
    width: 100%;
    border-radius: 0.8rem;
    position: relative;
    margin-bottom: 3.2rem;
    overflow: hidden;

    img {
      border-radius: 0.8rem;
    }

    .post-image-inner {
      width: 100%;
      padding-bottom: 56.25%;
    }
  }

  ${(props) => props.theme.mq.large`
      display: flex;
      width: 100%;

     .post-details {
        order: 0;
        padding: 0;
        background: transparent;
        box-shadow: none;
        padding-right: 2rem;
        flex-shrink: 1;
        width: 100%;

        p {
          width: 100%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin-bottom: 2rem;
        }
     }
     .post-image {
       order: 1;
       width: 20rem;
       height: 15rem;
       flex-shrink: 0;
       border-radius: .8rem;
       margin-bottom: 0;

       .post-image-inner {
          display: none;
          padding: 0;
        }
     }
    `}
  ${(props) => props.theme.mq.small`
     .post-details {
       width: 100%;
        p {
          display: none;
        }
     }
     .post-image {
       order: 1;
       width: 9.6rem;
       height: 9.6rem;
       margin-bottom: 0;
     }
    `}
`;

const Wrapper = styled.div`
  .posts-list {
    display: flex;
    flex-wrap: wrap;
    gap: 6.4rem;
    margin-bottom: 6.4rem;

    ${(props) => props.theme.mq.small`
     gap: 4rem;
     margin-bottom: 4rem;
    `}
  }

  .controls {
    margin-bottom: 12.8rem;
    a {
      ${(props) => props.theme.font.buttonLargeSecondary};
    }

    ${(props) => props.theme.mq.small`
     margin-bottom: 9.6rem;
    `}
  }

  .previous {
    margin-right: 2rem;
  }

  ${(props) =>
    !props.shouldShowNext &&
    `
    .next {
      display: none !important;
    }
  `}
  ${(props) =>
    !props.shouldShowPrevious &&
    `
    .previous {
      display: none !important;
    }
  `}
`;

PostList.propTypes = {
  posts: PropTypes.array.isRequired,
  pageNumber: PropTypes.number,
  hasNextPage: PropTypes.bool,
  overridePrev: PropTypes.bool,
  overrideNext: PropTypes.bool,
  featuredPost: PropTypes.object,
  showControls: PropTypes.bool,
};

export default function PostList({
  posts,
  pageNumber,
  hasNextPage,
  overridePrev,
  overrideNext,
  featuredPost,
}) {
  return (
    <Wrapper
      shouldShowPrevious={pageNumber > 1 || overridePrev}
      shouldShowNext={hasNextPage || overrideNext}
    >
      {featuredPost && (
        <div className="featured-post">
          <FeaturedPost featuredPost={featuredPost} />
        </div>
      )}
      <div className="posts-list">
        {posts &&
          posts.map((p) => (
            <PostLink
              title={p.data.title}
              className="post"
              to={`/post/${p.uid}`}
              key={p.uid}
            >
              <div className="post-image">
                <div className="post-image-inner"></div>
                {p.data.og_image.gatsbyImageData && (
                  <BackgroundImage
                    image={p.data.og_image.gatsbyImageData}
                    alt={p.data.title}
                  />
                )}
              </div>
              <div className="post-details">
                <div className="post-details-inner">
                  <h2>{p.data.title}</h2>
                  <div className="post-snippet">
                    <p>{p.data.meta_description}</p>
                  </div>
                  <small>{p.data.publish_date}</small>
                </div>
              </div>
            </PostLink>
          ))}
      </div>

      {pageNumber && (
        <div className="controls">
          <Link
            className="previous"
            to={pageNumber === 2 ? '/blog' : `/blog/${pageNumber - 1}`}
          >
            Previous
          </Link>

          <Link className="next" to={`/blog/${pageNumber + 1}`}>
            Next
          </Link>
        </div>
      )}
      {(overridePrev || overrideNext) && (
        <div className="controls">
          <Link className="previous" to={overridePrev}>
            Previous
          </Link>

          <Link className="next" to={overrideNext}>
            Next
          </Link>
        </div>
      )}
    </Wrapper>
  );
}
